import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import {lazy } from 'react';

import './App.scss';

const Home = lazy(() => import('./03_NAVIGATION/01_home/home.jsx'));


function App() {

  return (
    <div className="App">
      <Router>
        <div className='vedstatus'>
            <Routes>
              <Route path="/" element={<Home />} />


            </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
